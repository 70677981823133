@font-face {
  font-family: "Poppins Regular";
  src: url("../../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins Semi Bold";
  src: url("../../fonts/Poppins/Poppins-SemiBold.ttf");
}


@font-face {
  font-family: "Poppins Bold";
  src: url("../../fonts/Poppins/Poppins-Bold.ttf");
}
