//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  font-family: "Poppins Regular", sans-serif;
}

.main-header {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1)),
    url(../img/backgrounds/schloss-biebrich-bg-2k.jpg);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__logo {
    position: absolute;
    left: 40px;
    top: 40px;
    width: 300px;

    @media (max-width: map-get($container-max-widths, 'md')) {
      position: static;
      margin-bottom: 20px;
    }
  }

  &__device {
    max-width: 300px;

    @media (max-width: map-get($container-max-widths, 'md')) {
      max-width: 200px;
    }
  }

  .badge-container {
    margin: 20px 0;
    display: flex;

    @media (max-width: map-get($container-max-widths, 'md')) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .store-badge {
        height: 40px;
        margin: 2px 0;
      }
    }

    .store-badge {
      height: 80px;
      margin: 0 2px;

      @media (max-width: map-get($container-max-widths, 'md')) {
        height: 60px;
        margin: 5px 0;
      }
    }
  }
}

#biebrich-to-go {
  padding: 100px 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(250, 255, 238, 1) 100%);
  box-shadow: 0 -1px  1px rgba(0,0,0,0.12), 
              0 -2px  2px rgba(0,0,0,0.12), 
              0 -4px  4px rgba(0,0,0,0.12), 
              0 -8px  8px rgba(0,0,0,0.12),
              0 -16px 16px rgba(0,0,0,0.12);

  .info-text {
    margin: 1rem 0;
    font-size: 1.3rem;
  }

  #foerderung-title {
    color: rgb(81, 81, 81);
  }
}

#hello-biebrich {
  padding: 100px 0;
  color: white;
  background: rgb(41, 41, 41);
  background: linear-gradient(180deg, rgba(40, 48, 72, 1) 0%, rgb(63, 65, 66) 100%);
  background-image: url(../img/backgrounds/schloss-biebrich-bg-blurred-59.jpg);
  background-size: cover;

  h2 {
    font-size: 1.2rem;
    color: white;
    font-family: 'Poppins Semi Bold', sans-serif;
    text-shadow: 5px 5px 22px rgba(0, 0, 0, 1);

    @media (max-width: 1200px) {
      font-size: 1rem;
    }
  }

  h2.title {
    font-size: 2.7rem;
    text-shadow: 10px 10px 18px rgba(0, 0, 0, 1);
  }

  .info-text {
    font-size: 1.3rem;
  }

  .row img {
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0,0,0,0.7));
    filter: drop-shadow(3px 3px 2px rgba(0,0,0,0.7));

    @media (max-width: 767px) {
      width: 50%;
      display: block;
      margin: 0 auto;
    }
  }
}

.main-footer {
  padding: 20px 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(250, 255, 238, 1) 100%);

  &__links {
    display: flex;
    align-items: center;

    @media (max-width: 555px) {
      flex-direction: column;
    }

    a {
      padding-right: 10px;
      color: darken($primary, 10);

      @media (max-width: 555px) {
        padding-right: 0px;
        padding-top: 10px;
      }
    }

    .store-badge {
      height: 40px;
    }
  }
}

#terms-and-conditions {
  padding: 40px 0;

  .btn {
    border-radius: 0;
  }

  h1 {
    color: $primary;
    margin: 30px 0;
    font-family: "Poppins Semi Bold", sans-serif;
  }

  h2, h3 {
    color: darken($primary, 20);
  }

  h3 {
    margin-top: 20px;
  }
}